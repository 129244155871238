<template>
  <router-link
    class="table-router-link"
    :to="{ name: 'Card', params: { id: row.id } }"
  >
      {{ row.info.last_name + ' ' + row.info.first_name + ' ' + row.info.middle_name }}
  </router-link>
</template>

<script>

export default {
  name: "router-link-to-card-column-template",
  components: {},

  props: {
    row: {type: Object, required: true}
  },

  data() {
    return {}
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {},

}
</script>

<style>
.table-router-link{
  text-decoration: none;
}
</style>